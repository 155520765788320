import { ISubmitFormData, SetSteps, Steps } from '@/models/forms';
import { IconToBack } from '@/public/assets/svges/IconToBack';
import { useAnimation } from '@/hooks/useAnimation';
import { AuthAnimationOptions, AuthAnimations } from '@/constants/animation';
import { FocusEvent, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCuurentFormatData } from '@/helper/form';
import { InputUI } from '@/components/ui/InputUI';
import { MutationFunction, UseMutationResult, useMutation } from 'react-query';
import { sendQuoteData } from '@/constants/service';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { LoadingUI } from '@/components/ui/LoadingUI';
import { handleTypeChangeName, hendleTypeRemoveSpace } from '@/helper/strings';
import { IMaskInput } from 'react-imask';
import { ThankYouContext } from '@/context/thankYou';
import * as yup from "yup";
import classes from './index.module.css';

interface Iprops {
    setSteps: SetSteps;
    steps: Steps
};

interface IformData {
    name: string;
    email: string;
    phone: string;
}

const schema = yup.object().shape({
    name: yup.string().min(2, 'must be at least 2 characters').max(32, 'must be at least 32 characters').required(),
    phone: yup.string().min(12, "Invalid phone number").matches(new RegExp('[0-9]')).required(),
    email: yup.string().matches(
        /[A-z0-9]+@{1}[A-z0-9]+\.[A-z]{2,}$/,
      'Invalid email'
    )
    .required('Email is required'),
});

const sendCall: MutationFunction<any, ISubmitFormData> = async (formData: ISubmitFormData) => {
    const response = await sendQuoteData(formData);
    const clonedResponse = response.clone();
    return await clonedResponse.json();
};

const QuoteForm: React.FC<Iprops> = ({ setSteps, steps }) => {
    const { push, pathname } = useRouter();
    const [animationDivRef, animate] = useAnimation<HTMLFormElement>(AuthAnimationOptions);
    const { openEntryThankYou } = useContext(ThankYouContext);
    const defaultValues = steps[3].values as IformData;

    const {
        register,
        handleSubmit,
        control,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm<IformData>({
        resolver: yupResolver(schema),
        defaultValues
    });

    // Mutation
    const { mutate, isLoading }: UseMutationResult<
        any, unknown, ISubmitFormData, unknown
    > = useMutation(
        'quoteForm', sendCall, {
        onSuccess: () => {
            reset();
            openEntryThankYou();
            if(pathname === '/thank-you') {
                toast.success('your message is successfully sent', {
                    position: toast.POSITION.TOP_RIGHT
                });
                const scrollEl = document.querySelector('.thankYouPRef');
                scrollEl?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            } else {
                push('/thank-you');
            };
        },
        onError: () => {
            toast.error('sorry something is wrong', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    });

    useEffect(() => {
        animate(AuthAnimations.fromRight);
    }, []);

    const intoBack = () => {
        setSteps(prevSteps => prevSteps.map(step => ({
            ...step,
            active: step.id === 3
        })));
    };

    const onSubmit = (data: IformData) => {
        if(!isLoading) {
            const newSteps = steps.map((step: any) => ({
                ...step,
                ...(step.id === 4 && { 
                    values: data
                })
            }));
            setSteps(newSteps);
            const formData = getCuurentFormatData(newSteps);
            mutate(formData);
        };
    };

    return (
        <form className={classes.form} ref={animationDivRef} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.wrapperHeading}>
                <div className={classes.back} onClick={intoBack}>
                    <IconToBack />
                    <span>Edit</span>
                </div>
                <h2 className={classes.formHeading}>Get A <span>FREE</span> Quote</h2>
            </div>
            <div className={classes.wrapperInput}>
                <div className={classes.name}>
                    <InputUI
                        handleChange={({ event }) => {
                            handleTypeChangeName({ event });
                            clearErrors('name')
                        }}
                        name='name'
                        placeholder='Enter full name'
                        label='Name'
                        registerOption={{ minLength: 2 }}
                        register={register}
                        defaultValue={defaultValues.name}
                        error={errors.name?.message}
                    />
                </div>
                <div className={classes.phone}>
                    <InputUI
                        name='email'
                        placeholder='example@domain.com'
                        label='Email'
                        registerOption={{ minLength: 2 }}
                        register={register}
                        handleChange={({ event }) => {
                            hendleTypeRemoveSpace({ event });
                            clearErrors('email')
                        }}
                        defaultValue={defaultValues.email}
                        error={errors.email?.message}
                    />
                </div>
            </div>
            <div className={classes.email}>
                <Controller
                    name='phone'
                    control={control}
                    render={({ field }) => <InputUI
                        label='Phone number'
                        error={errors.phone?.message}
                        element={<IMaskInput
                            placeholder='Enter Phone number'
                            type="tel"
                            mask="(#00) 000-0000"
                            definitions={{"#": /[1-9]/}}
                            {...register('phone')}
                            {...field} 
                                                   
                        />}
                    />}
                />
            </div>
            <button className={classes.sendBtn} type='submit'>
                Continue
                {isLoading && <LoadingUI type='roundSmall'/>}
            </button>
        </form>
    );
};

export { QuoteForm };