import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Exclamation } from '@/public/assets/svges/Exclamation';
import { IVehicleGroup, SetSteps, Steps } from '@/models/forms';
import { IconToBack } from '@/public/assets/svges/IconToBack';
import { AddIcon } from '@/public/assets/svges/AddIcon';
import { DropdownSelectUI } from '@/components/ui/DropdownSelectUI';
import { timeOptions } from '@/constants/options';
import { useAnimation } from '@/hooks/useAnimation';
import { AuthAnimationOptions, AuthAnimations } from '@/constants/animation';
import { TooltipUI } from '@/components/ui/TooltipUI';
import { hendleTypeRemoveSpace } from '@/helper/strings';
import CheckBox from '@/components/ui/CheckBoxUI';

import classes from './index.module.css';

interface Iprops {
    setSteps: SetSteps;
    data: any;
};

interface ISelectedErrors {
    selectedItemError: boolean;
    shippingMethodError: boolean;
    operableMethodError: boolean;
};

const initialVehicleGroup = {
    make: '',
    model: '',
    year: '',
    id: 1
};

const OptionsForm: React.FC<Iprops> = ({ setSteps, data }) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm<Record<string, any> | any>();

    const [selectedItem, setSelectedItem] = useState<string>('');
    const [shippingMethod, setShippingMethod] = useState<1 | 2 | null>(null);
    const [operableMethod, setOperableMethod] = useState<1 | 2 | null>(null);
    const [vehicleGroup, setVehicleGroup] = useState<Array<IVehicleGroup>>(data.vehicle.length ? data.vehicle.map((vehicl: any, index: number) => ({
        ...vehicl,
        id: index + 1
    })) : [initialVehicleGroup]);
    const [animationDivRef, animate] = useAnimation<HTMLFormElement>(AuthAnimationOptions);
    const [selectedErrors, setSelectedErrors] = useState<ISelectedErrors>({
        selectedItemError: false,
        shippingMethodError: false,
        operableMethodError: false,
    });

    useEffect(() => {
        animate(AuthAnimations.fromRight, () => {
            animationDivRef.current?.scroll({
                top: 9999999999,
                behavior: 'smooth'
            });
        });

        if(selectedItem !== data.time) {
            setSelectedItem(data.time);
        };
        if(shippingMethod !== data.method) {
            setShippingMethod(data.method);
        };
        if(operableMethod !== data.operable) {
            setOperableMethod(data.operable);
        };
    }, []);

    useEffect(() => {
        if( selectedErrors.operableMethodError ||
            selectedErrors.selectedItemError ||
            selectedErrors.shippingMethodError
        ) {
            setSelectedErrors({
                operableMethodError: !Boolean(operableMethod),
                selectedItemError:   !Boolean(selectedItem),
                shippingMethodError: !Boolean(shippingMethod)
            });
        };
    }, [selectedItem, shippingMethod, operableMethod]);

    const intoBack = () => {
        setSteps(prevSteps => prevSteps.map(step => ({
            ...step,
            active: step.id === 1
        })));
    };

    const onSubmit = (dataVct: any) => {
        const vehicle = Object.entries(dataVct).reduce((acc: any, [key, val]) => {
            const num = parseInt(key.slice(-1));
            const prop = key.slice(0, -1);
            if (!isNaN(num) && val) {
                const group = acc[num - 1] || {};
                return [...acc.slice(0, num - 1), {...group, [prop]: val}, ...acc.slice(num)];
            };
            return acc;
        }, []);

        if(selectedItem && operableMethod && shippingMethod) {
            animate(AuthAnimations.toLeft, () => {
                setSteps((prev: Steps): Steps => prev.map((step) => ({
                    ...step,
                    active: step.id === 3 ? true : false,
                    ...(step.id === 2 && { values: {
                        ...step.values,
                        vehicle,
                        time: selectedItem,
                        operable: operableMethod,
                        method: shippingMethod
                    }})
                })));
            });
        } else {
            setSelectedErrors({
                operableMethodError: !Boolean(operableMethod),
                selectedItemError: !Boolean(selectedItem),
                shippingMethodError: !Boolean(shippingMethod)
            });
        }
    };

    const handleChangeYear = (event: ChangeEvent<HTMLInputElement>) => {
        const {target, target: { value, name }} = event;
        target.value = value.replace(/\D/g, '').slice(0, 4);
        const parsedYear = parseInt(value, 10);
        if(true === Boolean(parsedYear >= 1920 && parsedYear <= new Date().getFullYear() + 2)) {
            setValue(name, value, { shouldValidate: false });
        };
        clearErrors(name);
    };

    const addList = (): void => {
        vehicleGroup.push({...initialVehicleGroup, id: vehicleGroup.length + 1});
        setVehicleGroup([...vehicleGroup]);
    };

    const removeList = (): void => {
        const id = vehicleGroup[vehicleGroup.length - 1].id;
        reset({
            [`make${id}`]: '',
            [`model${id}`]: '',
            [`year${id}`]: ''
        });
        vehicleGroup.pop();
        setVehicleGroup([...vehicleGroup]);
    };

    const selectItem = (text: string): void => {
        setSelectedItem(text);
    };

    const selectCheckBox = (num: 1 | 2, category: 'operable' | 'method') => {
        category === 'method' && setShippingMethod(num);
        category === 'operable' && setOperableMethod(num);
    };

    const callbackListnerDropdown = () => {
        animationDivRef.current?.scroll({
            top: 9999999999,
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} ref={animationDivRef}>
            <div className={classes.wrapperHeading}>
                <div className={classes.back} onClick={intoBack}>
                    <IconToBack />
                    <span>Edit</span>
                </div>
                <h2 className={classes.formHeading}>Get A <span>FREE</span> Quote</h2>
            </div>
            <label className={classes.formLable}>
                Vehicle 
                <TooltipUI content="tooltip" direction="right">
                    <Exclamation />
                </TooltipUI>
            </label>
            { vehicleGroup.map((vehicle, index) => {
                return (
                <div className={classes.vehicleGroup} key={index}>
                    <div className={classes.wrapperFormInput}>
                        <input
                            className={classes.formInput}
                            {...register("year"+vehicle.id, {
                                required: true,
                                validate: (value) => {
                                    const parsedYear = parseInt(value, 10);
                                    return parsedYear >= 1920 && parsedYear <= new Date().getFullYear() + 1;
                                }
                            })}
                            onChange={handleChangeYear}
                            placeholder='Year'
                            defaultValue={data.vehicle.length && data.vehicle[index] ? data.vehicle[index].year : ''}
                        />
                        {errors["year"+vehicle.id] ? <span className={classes.error}>invalid year</span> : null}
                    </div>
                    <div className={classes.wrapperFormInput}>
                        <input
                            className={classes.formInput}
                            {...register("make"+vehicle.id, { required: true })}
                            onChange={(event) => {
                                hendleTypeRemoveSpace({event})
                                clearErrors("make"+vehicle.id);
                            }}
                            placeholder='Make'
                            defaultValue={data.vehicle.length && data.vehicle[index] ? data.vehicle[index].make : ''}
                        />
                        {errors["make"+vehicle.id] ? <span className={classes.error}>required</span> : null}
                    </div>
                    <div className={classes.wrapperFormInput}>
                        <input
                            className={classes.formInput}
                            {...register("model"+vehicle.id, { required: true })}
                            onChange={(event) => {
                                hendleTypeRemoveSpace({event})
                                clearErrors("model"+vehicle.id);
                            }}
                            placeholder='Model'
                            defaultValue={data.vehicle.length && data.vehicle[index] ? data.vehicle[index].model : ''}
                        />
                        {errors["model"+vehicle.id] ? <span className={classes.error}>required</span> : null}
                    </div>
                </div>
            )})}
            <div className={classes.editLists}>
                <div className={classes.addList} onClick={addList}>
                    <AddIcon />
                    <span>Add Multiple Vehicle</span>
                </div>
                { vehicleGroup.length > 1 && (
                    <div className={classes.removeList} onClick={removeList}>
                        <AddIcon rotate={45}/>
                        <span>Remove Vehicle</span>
                    </div>
                )}
            </div>
            <div className={classes.selectConditions}>
                <div className={classes.timeBlock}>
                    <label className={classes.formLable}>
                        Time
                        <TooltipUI content="tooltip" direction="right">
                            <Exclamation />
                        </TooltipUI>
                    </label>
                    <DropdownSelectUI
                        items={timeOptions}
                        selectedItem={selectedItem}
                        setSelectedItem={selectItem}
                        callbackListnerDropdown={callbackListnerDropdown}
                    />
                    {selectedErrors.selectedItemError && <span className={classes.error}>required</span>}
                </div>
                <div className={classes.checkboxes}>
                    <div className={classes.shippingMethod}>
                        <label className={classes.formLable}>
                            Shipping Method?
                            <TooltipUI content="tooltip" direction="right">
                                <Exclamation />
                            </TooltipUI>
                        </label>
                        <div className={classes.wrapperCheckbox}>
                            <div className={classes.checkboxList} onClick={() => selectCheckBox(1, 'method')}>
                                <CheckBox
                                    checked={shippingMethod === 1 || data.method === 1}
                                    onChange={() => selectCheckBox(1, 'method')}
                                />
                                <label className={classes.formLable}>Open</label>
                            </div>
                            <div className={classes.checkboxList} onClick={() => selectCheckBox(2, 'method')}>
                                <CheckBox
                                    checked={shippingMethod === 2 || data.method === 2}
                                    onChange={() => selectCheckBox(2, 'method')}
                                />
                                <label className={classes.formLable}>Enclosed</label>
                            </div>
                            {selectedErrors.shippingMethodError && <span className={classes.error}>required</span>}
                        </div>
                    </div>
                    <div className={classes.operableMethod}>
                        <label className={classes.formLable}>
                            Is It Operable?
                            <TooltipUI content="tooltip" direction="left">
                                <Exclamation />
                            </TooltipUI>
                        </label>
                        <div className={classes.wrapperCheckbox}>
                            <div className={classes.checkboxList} onClick={() => selectCheckBox(1, 'operable')}>
                                <CheckBox checked={operableMethod === 1 || data.operable === 1} onChange={() => selectCheckBox(1, 'operable')}/>
                                <label className={classes.formLable}>Yes</label>
                            </div>
                            <div className={classes.checkboxList} onClick={() => selectCheckBox(2, 'operable')}>
                                <CheckBox checked={operableMethod === 2 || data.operable === 2} onChange={() => selectCheckBox(2, 'operable')}/>
                                <label className={classes.formLable}>No</label>
                            </div>
                            {selectedErrors.operableMethodError && <span className={classes.error}>required</span>}
                        </div>
                    </div>
                </div>
            </div>
            <button className={classes.sendBtn} type='submit'>Continue</button>
        </form>
    );
};

export { OptionsForm };