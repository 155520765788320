import React, { useContext } from 'react';
import { EarringIcon } from '@/public/assets/svges/EarringIcon';
import { MailIcon } from '@/public/assets/svges/MailIcon';
import { GlobalDatasContext } from '@/context/globalDatas';
import { LINKS_FROM_MENU_TITLES } from '@/constants/words';
import { useRouter } from 'next/router';
import Link from 'next/link';
import classes from './index.module.css';
import classNames from 'classnames';

interface IProps {
    color?: 'white'
}

const NavBar: React.FC<IProps> = ({ color }) => {
    const { menus: { data, error } } = useContext(GlobalDatasContext);
    const { pathname, query } = useRouter();

    const isWhite = Boolean(query.dynamicPage || pathname === '/news' || pathname === '/blogs') === false

    const paintedData = (data?.topHeaderItems || []).reduce((acc, item) => {
        if(item.title === LINKS_FROM_MENU_TITLES.left) acc.left = item.children;
        if(item.title === LINKS_FROM_MENU_TITLES.center) acc.center = item.children;
        if(item.title === LINKS_FROM_MENU_TITLES.right) acc.right = item.children;
        return acc;
    }, { left: [], center: [], right: [] });

    if(data === null ||  error !== null) return null;

    return (
        <header className={classNames(classes.header, {
            [classes.colorWhite]: isWhite || color 
        })}>
            <Link
                href={`${paintedData.left[0]?.title === LINKS_FROM_MENU_TITLES.mail ? 'mailto' : 'tel'}:${paintedData.left[0]?.url || ''}`}
                className={classNames(classes.flex, {
                    [classes.phoneNumber]: paintedData.left[0]?.title === LINKS_FROM_MENU_TITLES.phone,
                    [classes.mail]: paintedData.left[0]?.title === LINKS_FROM_MENU_TITLES.mail
                })}
            >
                { paintedData.left[0]?.title === LINKS_FROM_MENU_TITLES.phone ? <EarringIcon /> : <MailIcon />}
                <p>{paintedData.left[0]?.url}</p>
            </Link>
            <nav className={classes.navBar}>
                { paintedData.center.map(({ title, url, id }) => (
                    <Link
                        className={classes.link}
                        href={'/' + url}
                        key={id}
                    >{title}</Link>
                ))}
            </nav>
            <Link
                href={`${paintedData.right[0]?.title === LINKS_FROM_MENU_TITLES.phone ? 'tel' : 'mailto'}:${paintedData.right[0]?.url || ''}`}
                className={classNames(classes.flex, {
                    [classes.phoneNumber]: paintedData.right[0]?.title === LINKS_FROM_MENU_TITLES.phone,
                    [classes.mail]: paintedData.right[0]?.title === LINKS_FROM_MENU_TITLES.mail
                })}
            >
                { paintedData.right[0]?.title === LINKS_FROM_MENU_TITLES.phone ? <EarringIcon /> : <MailIcon />}
                <p>{paintedData.right[0]?.url}</p>
            </Link>
        </header>
    );
};

export { NavBar }
