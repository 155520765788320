import { useForm } from 'react-hook-form';
import { IRoutFormData, SetSteps } from '@/models/forms';
import { useAnimation } from '@/hooks/useAnimation';
import { AuthAnimationOptions, AuthAnimations } from '@/constants/animation';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputUI } from '@/components/ui/InputUI';
import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { getOptions } from '@/constants/service';
import { hendleTypeRemoveSpace } from '@/helper/strings';
import * as yup from "yup";
import classNames from 'classnames';
import classes from './index.module.css';

interface Iprops {
    setSteps: SetSteps;
    data: any;
};

const schema = yup.object().shape({
    form: yup
        .string()
        .test('is-valid-form', 'Invalid form input', (value) => {
            const hasString = /[a-zA-Z]{2}/.test(value!);
            const isFiveDigits = /^\d{5}$/.test(value!);
            return (hasString && !isFiveDigits) || (!hasString && isFiveDigits);
        })
        .min(2, 'must be at least 2 characters')
        .max(32, 'must be at most 32 characters')
        .required(),
    to: yup
        .string()
        .test('is-valid-to', 'Invalid to input', (value) => {
            const hasString = /[a-zA-Z]{2}/.test(value!);
            const isFiveDigits = /^\d{5}$/.test(value!);
            return (hasString && !isFiveDigits) || (!hasString && isFiveDigits);
        })
        .min(2, 'must be at least 2 characters')
        .max(32, 'must be at most 32 characters')
        .required(),
});

const RoutForm: React.FC<Iprops> = ({ setSteps, data }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        clearErrors
    } = useForm<IRoutFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            form: data.form,
            to: data.to
        }
    });
    const { form, to } = watch();

    const { data: optionsForm, isLoading: loadingForm } = useQuery(['optionsForm', form], getOptions(form), {
        enabled: Boolean(form)
    });

    const { data: optionsTo, isLoading: loadingTo } = useQuery(['optionsTo', to], getOptions(to), {
        enabled: Boolean(to)
    });

    const [animationDivRef, animate] = useAnimation<HTMLFormElement>(AuthAnimationOptions);

    useEffect(() => {
        animate(AuthAnimations.fromRight);
    }, []);

    const onSubmit = (data: IRoutFormData) => {
        animate(AuthAnimations.toLeft, () => {
            setSteps(steps => steps.map(step => ({
                ...step,
                values: step.id === 1 ? data : step.values,
                active: step.id === 2 ? true : false
            })));
        });
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} ref={animationDivRef}>
            <h2 className={classes.formHeading}>Get A <span>FREE</span> Quote</h2>
            <div className={classNames(classes.formInputGroup, classes.inputGroup)}>
                <InputUI
                    label='From'
                    labelIcon={true}
                    error={errors.form && errors.form.message}
                    element={<Autocomplete
                        onBlur={() => clearErrors('form')}
                        className={'autocomplete'}
                        options={optionsForm?.data || []}
                        clearOnBlur={false}
                        loading={loadingForm}
                        value={form}
                        renderInput={(params) => <TextField
                            onSelect={(event) => {
                                const { value } = event.target as any;
                                setValue('form', value);
                            }}
                            {...params}
                            {...register('form')}
                            onChange={(event) => hendleTypeRemoveSpace({event})}
                            placeholder="City,State or ZIP"
                        />}
                    />}
                />
            </div>
            <div className={classNames(classes.toInputGroup, classes.inputGroup)}>
                <InputUI
                    label='To'
                    labelIcon={true}
                    error={errors.to && errors.to.message}
                    element={<Autocomplete
                        onBlur={() => clearErrors('to')}
                        className={'autocomplete'}
                        options={optionsTo?.data || []}
                        clearOnBlur={false}
                        loading={loadingTo}
                        value={to}
                        renderInput={(params) => <TextField
                            onSelect={(event) => {
                                const { value } = event.target as any;
                                setValue('to', value);
                            }}
                            {...params}
                            {...register('to')}
                            placeholder="City,State or ZIP"
                        />}
                    />}
                />
            </div>
            <button className={classes.button} type="submit">Continue</button>
        </form>
    );
};

export { RoutForm };