import { useContext, useState } from 'react';
import { RouteHistory } from '../routeHistory';
import { Form } from '../forms/Form';
import { Steps } from '@/models/forms';
import { GlobalDatasContext } from '@/context/globalDatas';
import useWindowSize from '@/hooks/useWindowSize';
import Fade from 'react-reveal';

import classes from './index.module.css';

const SelectForms: React.FC = () => {
    const { home: { data } } = useContext(GlobalDatasContext);
    const { width } = useWindowSize();
    const [ steps, setSteps ] = useState<Steps>([
        {
            id: 1,
            text: 'Select a Rout',
            active: true,
            values: {
                form: '',
                to: ''
            }
        },
        {
            id: 2,
            text: 'Select an Options',
            active: false,
            values: {
                vehicle: [],
                operable: null,
                method: null,
                time: ''
            },
        },
        {
            id: 3,
            text: 'Confirmation',
            active: false
        },
        {
            id: 4,
            text: 'Get a Quote',
            active: false,
            values: {
                name: '',
                phone: '',
                email: ''
            }
        }
    ]);

    return (
        <div className={classes.root}>
            <RouteHistory steps={steps} />
            <div className={classes.form}>
                <Form
                    steps={steps}
                    setSteps={setSteps}
                    id={(steps.find(step => step.active)!).id}
                />
                { Boolean(Number(width) > 1024) && (
                    <Fade right>
                        <div className={classes.wrapperDescription}>
                            { data?.bannerTitle &&
                                <h1
                                    className={classes.title}
                                    dangerouslySetInnerHTML={{
                                        __html: data.bannerTitle
                                            .replace(/\s+/g, ' ')
                                            .replace('Columbus Auto', '<span>Columbus Auto</span>')
                                    }}
                                />
                            }
                            <p className={classes.description}>
                                { data!.bannerDescription }
                            </p>
                        </div>
                    </Fade>
                )}
            </div>
        </div>
    );
};
export { SelectForms };