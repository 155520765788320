import React, { useMemo } from 'react';
import classNames from 'classnames';
import useWindowSize from '@/hooks/useWindowSize';

import classes from './index.module.css';

type StepsId = 1 | 2 | 3 | 4;

interface Iprops {
    steps: Array<{
        id: StepsId,
        text: string,
        active: boolean
    }>
};

const RouteHistory: React.FC<Iprops> = ({ steps }) => {
    const { width } = useWindowSize();

    const isMobile = useMemo(() => Number(width) <= 768, [width]);
    const activeStep = steps.find(step => step.active)!;
    const paintedSteps = isMobile ? [activeStep, ...(activeStep.id !== 4 ? [ steps.find(step => step.id === activeStep.id + 1)! ] : [])] : steps;

    return (
        <div className={classes.routeHistory}>
            {paintedSteps.map((step, index) => (
                <div key={step.id} className={classes.step}>
                    <p className={classNames(classes.stepId, {
                        [classes.active]: step.active,
                    })}>
                        {step.id}
                    </p>
                    <p className={classNames(classes.stepText, {
                        [classes.active]: step.active,
                    })}>
                        {step.text}
                    </p>
                    { index < paintedSteps.length - 1 && (
                        <span className={classes.row} />
                    )}
                </div>
            ))}
        </div>
    );
};

export { RouteHistory };