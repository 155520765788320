import React, { useContext } from "react";
import { Container } from "@/components/ui/container";
import { SelectForms } from "./selectForms";
import { NavBar } from "@/components/layout/NavBar";
import { InformationBar } from "@/components/layout/InformationBar";
import { GlobalDatasContext } from "@/context/globalDatas";
import { useQuery } from "react-query";
import { getHome } from "@/constants/service";
import { setHomeDataAction } from "@/context/globalDatas/reducer/actions/home";
import { LoadingUI } from "@/components/ui/LoadingUI";
import useWindowSize from "@/hooks/useWindowSize";
import classes from './index.module.css';
import Image from "next/image";

interface IProps {
    color?: 'white'
};

const Hero: React.FC<IProps> = ({ color }) => {
    const { width } = useWindowSize();
    const { home: { data }, dispatch } = useContext(GlobalDatasContext);
    const isMobile = Number(width) <= 768;

    const { isLoading: isLoadingHomeData } = useQuery('home', getHome(), {
        onSuccess: data => dispatch(setHomeDataAction(data)),
        enabled: Boolean(!data)
    });

    if(isLoadingHomeData || !data) {
        return <LoadingUI type='fullPage'/>;
    };

    return (
        <section className={classes.heroSection}>
            {!isMobile && (
                <Image
                    alt="home page background image"
                    src={data!.bannerImage}
                    width={3840}
                    height={999}
                    className={classes.heroBg}
                    priority
                />
            )}
            <Container>
                {!isMobile && <NavBar color={color}/>}
                {!isMobile && <InformationBar color={color}/>}
                <SelectForms />
            </Container>
        </section>
    );
};

export { Hero };